import Layout from "../components/layout"
import { graphql } from "gatsby"
import NewsItem from "../components/news/NewsItem"
import SEO from "../components/seo"
import styled from '@emotion/styled'

export const pageQuery = graphql`
  query {
    allWordpressPost(
      filter: { categories: { elemMatch: { name: { eq: "blog" } } } }
    ) {
      totalCount
      nodes {
        id
        content
        date
        excerpt
        title
        slug
        tags {
          name
        }
      }
    }
  }
`
export interface NewsNode {
  id: string
  content: string
  date: string
  excerpt: string
  title: string
  slug: string
  tags: Array<{
    name: string
  }>
}

interface PageProps {
  data: PageQueryData
}


export interface PageQueryData {
  allWordpressPost: {
    totalCount: number
    nodes: NewsNode[]
  }
}

const languageTag = { en: "english", es: "spanish" }

const path = {
  en: "/blog-en",
  es: "/blog"
}
export const BlogI18n = (lang: string) => (props: PageProps) => {
  const items = props.data.allWordpressPost.nodes.filter(
    blogPostNode =>
      blogPostNode.tags && blogPostNode.tags.find(tag => tag.name === languageTag[lang])
  )

  return (
    <Layout lang={lang} pathEn={path.en} pathEs={path.es} currentPath={path[lang]}>
      <SEO title={{ es: "Blog", en: "Blog" }[lang]} />
      {items.length ? (
        items.map(newsNode => (
          <StyledNewsItem lang={lang} key={newsNode.id} newsNode={newsNode} />
        ))
      ) : (
        <h3>
          {
            {
              'es':'Actualmente no hay nada en el blog, peronto escribimos algo',
              'en':'There are no blog posts currently, soon we will write something'
            }[lang]
          }
        </h3>
      )}
    </Layout>
  )
}

const StyledNewsItem = styled(NewsItem)`
  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
`

export default BlogI18n("es")

import { BlogI18n } from "./blog"
import { graphql } from "gatsby"

export const pageQuery = graphql`
    query {
        allWordpressPost(
            filter: { categories: { elemMatch: { name: { eq: "blog" } } } }
        ) {
            totalCount
            nodes {
                id
                content
                date
                excerpt
                title
                slug
                tags {
                    name
                }
            }
        }
    }
`


export default BlogI18n("en")
